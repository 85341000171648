import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';
import pip from '../../assets/sounds/pip.mp3';

const QRScanner = ( {onScan} ) => {
  const [qrData, setQRData] = useState(null);
  const [cameraAccessGranted, setCameraAccessGranted] = useState(false);

  const handleScan = (data) => {
    if (data) {
      playSuccessSound()
      const lotId = (Number(data.split(',')[0].split(' ')[1]));
      setQRData(lotId);
      onScan(data);
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  function playSuccessSound() {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const soundSource = audioContext.createBufferSource();

    // Cargar el archivo de sonido
    const request = new XMLHttpRequest();
    request.open('GET', pip, true);
    request.responseType = 'arraybuffer';

    request.onload = () => {
      audioContext.decodeAudioData(request.response, (buffer) => {
        soundSource.buffer = buffer;
        soundSource.connect(audioContext.destination);
        soundSource.start(0);
      });
    };

    request.send();
  }

  useEffect(() => {
    // Verificar si el navegador admite la API de getUserMedia
    const isSupported = navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function';

    if (isSupported) {
      // Solicitar permiso para acceder a la cámara
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          // Permiso otorgado
          setCameraAccessGranted(true);
        })
        .catch((error) => {
          // Permiso denegado
          console.error('Error al acceder a la cámara:', error);
        });
    } else {
      console.error('La API getUserMedia no es compatible con este navegador.');
    }
  }, []);

  return (
    <div>
      <QrReader
        delay={1000}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
      {/* {!cameraAccessGranted && <p>Solicitando acceso a la cámara...</p>}
      {cameraAccessGranted && (
        <div>
          <QrReader
            delay={1000}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />
        </div>
      )} */}
    </div>
  );
};

export default QRScanner;
