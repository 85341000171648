/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// ui
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
// utils
import produce from 'immer';
import { truthty, falsy } from '../../../../utils/functions';
import QRScanner from '../../../../commons/components/QRScanner';
import { processActions } from "screens/Process/reducer";
import Entries from '../Entries';
import StyledTableCell from 'commons/components/StyledTableCell';

const EntryForm = (props) => {
  const {
    controls,
  } = props;

  const [state, setState] = useState({
    loading: false,
    errorMsg: '',
  });
  const isSmallScreen = window.innerWidth < 600;
  const [count, setCount] = useState(0);
  const [inputVal, setInputVal] = useState('');
  const [manualMode, setManualMode] = useState(true);
  const [lotNumber, setLotNumber] = useState('');
  const savedCallback = useRef();
  const {
    isOpen,classes, process, objects, toggleDelete,
    formControls, toggleForm, qrReader, qrCode,
    getProductsRows, getProductsHeaders, entryLots, printAllFnc
  } = props;


  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line no-use-before-define
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current();
    }

    const id = setInterval(tick, 500);
    return () => clearInterval(id);
  }, []);

  function onKeyPress(event) {
    if (!state.loading) {
      setState(produce((draft) => { draft.loading = true; }));
    }
    if (event.keyCode === 221) {
      qrReader(event, '');
      setCount(0);
    } else if (event.keyCode === 51) {
      const val = qrCode.split(',');
      if (val.length === 3) {
        props.onChangeFnc('entry', { lotId: Number(val[0].split(' ')[1]) }, {});
      }
    }
  }

  function validateCode(code) {
    let msg = '';
    const id = code.split(',')[0].split(' ')[1];
    props.onChangeFnc('entry', { id }, {});
    props.submit(id);
    setState(produce((draft) => { draft.loading = false; }));
    qrReader({}, '');
    return msg
  }

  function callback() {
    // eslint-disable-next-line no-undef
    const input = document.getElementById('lotInput');
    if (truthty(input)) {
      input.focus();
    }
    if (count <= 0 && truthty(qrCode)) {
      setCount(count + 1);
    }
    if (count > 0 && inputVal !== qrCode) {
      setInputVal(qrCode);
      setCount(0);
    } else if (count > 0 && inputVal === qrCode) {
      const errorMsg = validateCode(qrCode);
      if (errorMsg === '') {
        props.submit();
      } else {
        setState(produce((draft) => { draft.errorMsg = errorMsg; }));
      }
    }
  }

  const addLot = (lotNumber) => {
    props.submit();
  };

  const handleLotNumber = (value) => {
    setLotNumber(value);
    props.onChangeFnc('entry', { lotId: value === '' ? '' : Number(value) }, {});
  };


  // ---------------------------------
  const entryState = useSelector((state) => state.process.formControls.entry)
  const [qrScan, setQrCode] = useState('');
  const dispacher = useDispatch();


  const handleQrReader = async (value) => {
    const lotId = Number(value.split(',')[0].split(' ')[1])
    props.onChangeFnc('entry', { lotId }, {});
    props.submit(lotId);
  };

  // -----------------------------------
  const title = !manualMode && 'Escanear un código QR';

  const [camaraIsOpen, setCamaraIsOpen] = useState(false)

  const handleCamaraButton = () => {
    setCamaraIsOpen(!camaraIsOpen)
  }

  return (
    <Dialog
      // @ts-ignore
      TransitionProps={{ 'data-module': 'entry' }}
      fullWidth
      onClose={toggleForm}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle>Agregar entrada de pak</DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <FormControlLabel
            control={(
              <Switch
                checked={manualMode}
                onChange={() => setManualMode(!manualMode)}
              />
            )}
            label="Modo manual"
          />
        </Box>
        {state.loading
          ? (
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <Typography align="center" style={{ marginTop: 35 }}>
                  Cargando
                </Typography>
              </Grid>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )
          : (
            <Typography align="center" style={{ marginTop: 35 }}>
              {truthty(state.errorMsg) ? state.errorMsg : title}
            </Typography>
          )}
        {manualMode
          ? (

            <Grid container style={{ marginTop: 30, marginBottom: 25 }}>
              <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                  <TextField
                    label={isSmallScreen ? "Número de pak" : "Ingrese número de pak"}
                    type="number"
                    value={controls.lotId}
                    onChange={(e) => handleLotNumber(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box style={{ height: '100%' }} display="flex" justifyContent="center" alignItems="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => addLot(lotNumber)}
                    disabled={Number(lotNumber) <= 0}
                  >
                    Ingresar
                  </Button>
                </Box>
              </Grid>
            </Grid>

          ) : (
            <Grid container spacing={1} style={{ width:'100%' }} justifyContent='center'>
              <Grid item sm={1} xs={1}>
                <TextField
                  style={{
                    width: 0,
                    overflow: 'hidden',
                  }}
                  value={qrCode}
                  onChange={qrReader}
                  fullWidth
                  id="lotInput"
                  label="Leer lote"
                  onKeyDown={onKeyPress}
                  autoComplete="off"
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCamaraButton}
                  >
                    {camaraIsOpen ? ('CERRAR CÁMARA'):('ABRIR CÁMARA')}
                  </Button>
                </div>
              </Grid>
              <Grid item sm={8} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  {camaraIsOpen && (
                  <QRScanner onScan={handleQrReader}/>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        <Grid container spacing={1} style={{ width:'100%'}} justifyContent='center'>
            <Entries
              isGroupedInfo={formControls.headers.isGroupedInfo}
              classes={classes}
              process={process}
              lots={objects.lots}
              objects={objects}
              toggleDelete={toggleDelete}
              getProductsRows={getProductsRows}
              getProductsHeaders={getProductsHeaders}
              entryLots={entryLots}
              printAllFnc={printAllFnc}
              isSmallScreen={isSmallScreen}
            />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleForm} data-module="entry" color="primary" variant="outlined">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EntryForm;